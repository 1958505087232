<bui-button
  variant="utility"
  [loading]="isLoading"
  [disabled]="isLoading || disabled"
  [buiDropdownTrigger]="dropdown"
>
  Export
</bui-button>

<bui-dropdown #dropdown>
  <ng-container *ngFor="let option of options">
    <bui-dropdown-item
      *ngIf="option.value"
      (click)="onExport(option.value, option.data)"
    >
      {{ option.label }}
    </bui-dropdown-item>
  </ng-container>
</bui-dropdown>
