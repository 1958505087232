import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core'
import { RmdExportEvent, RmdExportOption } from './typings'

@Component({
  selector: 'rmd-export-button',
  templateUrl: 'rmd-export-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdExportButtonComponent {
  @Input() isLoading: boolean
  @Input() disabled: boolean
  @Input() options: RmdExportOption[]

  @Output() readonly export = new EventEmitter<RmdExportEvent>()

  public onExport(
    value: RmdExportEvent['value'],
    data?: RmdExportEvent['data']
  ): void {
    this.export.emit(data ? { value, data } : { value })
  }
}
